<template>
	<pui-datatable
		:modelName="modelName"
		:customizeActionAvailability="customizeActionAvailability"
		:parentModelName="parentModelName"
		:parentModel="parentModel"
		:externalFilter="externalFilter"
		:masterDetail="masterDetail"
		:readOnly="readOnly"
		:showMasterDetailFilterListBtn="true"
		:showMasterDetailFilterBtn="true"
		:showMasterDetailSortBtn="true"
		:showMasterDetailConfBtn="true"
	></pui-datatable>
</template>

<script>

import PuiGridDetailMixin from 'pui9-mixins/PuiGridDetailMixin';
import { STATUS_DRAFT_ID } from '../../constants/imofalforms/ImoFalFormsConstants';

export default {
	name: 'imofalform4-grid',
	mixins: [PuiGridDetailMixin],
	data() {
		return {
			modelName: 'imofalform4',
			modelColumnDefs: {
				indarrivaldeparture: {
					render: (data) => {
						if (data === '1') {
							return this.$t('grid.imofalforms4.isarrival');
						} else if (data === '2') {
							return this.$t('grid.imofalforms4.isdeparture');
						} else {
							return '';
						}
					}
				}
			}
		};
	},
	methods: {
		customizeActionAvailability(actionId, parentModel) {
			if (actionId === 'puiImportAction' || actionId === 'puiExportAction') {
				return parentModel.statusid === STATUS_DRAFT_ID;
			}
		}
	}
};
</script>
